import { createMuiTheme } from '@material-ui/core/styles';
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    maxContainerWidth: number;
    screenbreakpoint: {
      tablet: number;
      smartphone: number;
      large: number;
      xlarge: number;
      xsmall: number;
    };
    leftSideBarWidth: number;
    fonts: {
      default: string;
    };
    button: {
      primary: string;
      secondary: string;
      default: string;
      disabled: string;
    };

    colors: {
      border: {
        main: string;
      };
      text: {
        black: string;
        white: string;
        black00: string;
        black01: string;
        black02: string;
        black03: string;
        black004: string;
        tipsPrimaryTitle: string;
        tipsSecondaryTitle: string;
        lightPurple: string;
        grey: string;
      };
      background: {
        default: string;
        info: string;
        paper: string;
        input: string;
      };
    };
  }

  interface ThemeOptions extends Theme {
    guard?: string;
  }
}

const palette = {
  primary: { main: '#ea483a', dark: '#a33228', light: '#ee6c61' },
  secondary: {
    main: '#4aa1a0',
    dark: '#337070',
    light: '#6eb3b3',
  },
  text: {
    disabled: '#666666',
    black: '#1A1A1A',
  },

  border: {
    main: '#B3B3B3',
  },
};

/* theme customization */
export const DefaultTheme = createMuiTheme({
  maxContainerWidth: 1597,
  leftSideBarWidth: 256,
  screenbreakpoint: {
    smartphone: 768,
    tablet: 1024,
    large: 1440,
    xlarge: 1600,
    xsmall: 425,
  },
  spacing: (factor: number) => `${8 * factor}px`,
  typography: {
    fontFamily: ['"Inter"', '"Lexend"', 'sans-serif'].join(','),
    button: {
      fontFamily: ['"Inter"', '"Lexend"', 'sans-serif'].join(','),
    },
  },
  button: {
    primary: '#43A1A0',
    secondary: '#03CEA4',
    default: '#7D7D7D',
    disabled: '#F3F3F3',
  },
  fonts: {
    default: '"Inter", "Lexend", sans-serif',
  },
  palette: {
    primary: {
      main: palette.primary.main,
      dark: palette.primary.dark,
      light: palette.primary.light,
      //@ts-ignore
      lighter: '#D1EAFF',
      lightest: '#E1F5FE',
    },
    secondary: {
      main: palette.secondary.main,
      dark: palette.secondary.dark,
      light: palette.secondary.light,
    },
    error: { main: '#F44336' },
    success: { main: '#4CAF50', light: '#8BC34A' },
    border: {
      main: palette.border.main,
      light: '#E8E8E8',
      lighter: '#F2F2F2',
    },

    text: {
      disabled: palette.text.disabled,
    },

    icon: {
      blue: '#3d71cc',
      disabled: '#444A5D',
    },
  },
  colors: {
    border: {
      main: '#B3B3B3',
    },
    text: {
      black: '#000',
      white: '#fff',
      black00: '#032D23',
      black01: '#153B50',
      black02: '#263238',
      black03: '#626060',
      black004: '#667085',
      tipsPrimaryTitle: '#153B50',
      tipsSecondaryTitle: '#03CEA4',
      lightPurple: '#7B61FF',
      grey: '#E0E0E0',
    },
    background: {
      default: '#f7f5f5',
      info: '#36BFFA',
      paper: '#ffffff',
      input: '#FBFAFA',
    },
  },
});
