import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';
import GovMgBrandingImage from '../../../assets/images/gov-mg-brand.svg';
import MsanpBrandingImage from '../../../assets/images/msanp-brand.svg';
import FlagFrIcon from '../../../assets/images/flag-fr.svg';
import FlagMgIcon from '../../../assets/images/flag-mg.svg';
import { useLocaleContext } from '../../../modules/LocaleProvider';
import { EnumLocaleKeys } from '../../../modules/LocaleProvider/LocaleContext';
import { useStyles } from './styles';
import ActionButton from '../../button/ActionButton';

const PageHeader: FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { locale, setLocale } = useLocaleContext();
  return (
    <Box component="div" className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Box>
            <img
              className={classes.govBrand}
              src={GovMgBrandingImage}
              alt="GOV MG LOGO"
            />
          </Box>
          <ActionButton
            color="default"
            style={{ maxWidth: 'max-content', minWidth: 'auto' }}
            classes={{ label: classes.languageLabel }}
            tooltip={
              <p>
                {intl.formatMessage({
                  id:
                    EnumLocaleKeys.Francais === locale
                      ? 'common.language.mg.iso.tooltip'
                      : 'common.language.fr.iso.tooltip',
                })}
              </p>
            }
            onClick={() =>
              setLocale(
                EnumLocaleKeys.Francais === locale
                  ? EnumLocaleKeys.Malagasy
                  : EnumLocaleKeys.Francais,
              )
            }
          >
            <img
              src={EnumLocaleKeys.Francais === locale ? FlagMgIcon : FlagFrIcon}
              className={classes.flagIcon}
            />
            {intl.formatMessage({
              id:
                EnumLocaleKeys.Francais === locale
                  ? 'common.language.mg.iso'
                  : 'common.language.fr.iso',
            })}
          </ActionButton>
          <Box>
            <a
              href="http://www.sante.gov.mg/ministere-sante-publique"
              target="blank"
            >
              <img
                className={classes.msanpBrand}
                src={MsanpBrandingImage}
                alt="MSANP LOGO"
              />
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PageHeader;
