import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.background.paper,
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: theme.maxContainerWidth,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(theme.maxContainerWidth + 1)]: {
      padding: theme.spacing(1, 2, 1, 2),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      flexDirection: 'column',
    },
  },
  copyright: {
    color: theme.colors.text.black00,
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '-0.18px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.tablet + 1)]: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      paddingTop: theme.spacing(2),
    },
  },
  makerBrand: {
    width: 152,
    height: 51,
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  covaxBrand: {
    width: 147,
    height: 61,
  },
  copyrightWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      flexDirection: 'column',
    },
  },
}));
