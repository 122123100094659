import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CovaxBrandingImage from '../../../assets/images/covax-branding-image.svg';
import EgmBrandingImage from '../../../assets/images/new-ugd-logo.svg';
import ActionButton from '../../button/ActionButton';
import { useStyles } from './styles';

const PageFooter: FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box component="div" className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.copyrightWrapper}>
          <Typography className={classes.copyright}>
            {intl.formatMessage({ id: 'common.page.footer.copyright' })}
          </Typography>
        </Box>
        <Box>
          <a href="https://digital.gov.mg" target="blank">
            <img
              className={classes.makerBrand}
              src={EgmBrandingImage}
              alt="UGD LOGO"
            />
          </a>
        </Box>
        <Box>
          <img
            className={classes.covaxBrand}
            src={CovaxBrandingImage}
            alt="COVAX UNIT LOGO"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PageFooter;
