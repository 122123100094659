import React, { useState, FC, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import LockIcon from '@material-ui/icons/Lock';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Alert } from '@material-ui/lab';
import { usePageMetaContext } from '../../modules/PageMetaProvider';
import ConfirmButton from '../../components/button/ConfirmButton';
import { useSignIn } from '../../graphql/authentication/hooks';
import PageContainer from '../../components/page/PageContainer';
import Popup from 'reactjs-popup';
import HelloBar from './HelloBar';

import {
  DEFAULT_REGISTER_ROUTE,
  DEFAULT_FORGOTPASSWORD_ROUTE,
  DEFAULT_FAQ_ROUTE,
} from '../../consts';
import TipsContainer from '../../components/page/TipsContainer';
import TextInputField from '../../components/inputfields/TextInputField';
import TipsItem from '../../components/page/TipsContainer/TipsItem';

import { useFormik } from './formik';
import useStyles from './styles';
import { PERSONAS_PRIORITY } from './tips';
import TooltipsCard from './TooltipsCard';

const SignIn: FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const { signInLoading, signInMutation, closeAlert, errorAlert } = useSignIn();
  const [open, setOpen] = useState(false);
  const [openTooltips, setOpenTooltips] = useState(false);

  // Page context handler
  const { setPathLabels } = usePageMetaContext();

  // Update page context
  useEffect(() => {
    setPathLabels([
      intl.formatMessage({
        id: 'signin.page.title.label',
      }),
    ]);
    setTimeout(() => setOpen((o) => !o), 2500);
    setOpenTooltips((o) => (open ? o : !o));
  }, []);

  const formik = useFormik({
    onSubmit: async (values) => {
      signInMutation(values.username.trim(), values.password.trim());
    },
  });

  return (
    <PageContainer showBackground className={classes.mainContainer}>
      <TipsContainer
        primaryTitle={intl.formatMessage({
          id: 'signin.tips.primary.title',
        })}
        secondaryTitle={intl.formatMessage({
          id: 'signin.tips.secondary.title',
        })}
        hintMessage={intl.formatMessage({
          id: 'signin.subscribe.hint.message',
        })}
        hideHealinesOnTablet={true}
      >
        {PERSONAS_PRIORITY.map((personaItem, index) => (
          <TipsItem
            key={`${index}-${personaItem.caption}`}
            image={personaItem.image}
            caption={intl.formatMessage({ id: personaItem.caption })}
            className={classes.tipsItem}
          />
        ))}
      </TipsContainer>
      <Box component="div" className={classes.root}>
        <Paper className={classes.paper}>
          <Box component="div" className={classes.container}>
            <Box>
              <Typography
                component="h1"
                variant="h4"
                className={classes.loginCaption}
              >
                {intl.formatMessage({
                  id: 'signin.form.hint.label',
                })}
              </Typography>
              {errorAlert && (
                <Box component="div" className={classes.alertContainer}>
                  <Alert severity="error" onClose={() => closeAlert()}>
                    {errorAlert}
                  </Alert>
                </Box>
              )}
              <form
                className={classes.formBox}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <TextInputField
                  name="username"
                  id="username"
                  required
                  label={intl.formatMessage({
                    id: 'signin.login.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'signin.login.placeholder.label',
                  })}
                  fullWidth
                  disabled={signInLoading}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextInputField
                  name="password"
                  id="password"
                  required
                  type={showPassword ? 'text' : 'password'}
                  label={intl.formatMessage({
                    id: 'signin.password.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'signin.password.placeholder.label',
                  })}
                  fullWidth
                  disabled={signInLoading}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <IconButton size="small">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ConfirmButton
                  type="submit"
                  color="primary"
                  className={classes.actionButton}
                  disabled={!formik.isValid}
                  loading={signInLoading}
                >
                  {intl.formatMessage({
                    id: 'signin.buttom.label',
                  })}
                </ConfirmButton>
                <Link
                  to={DEFAULT_FORGOTPASSWORD_ROUTE}
                  className={classes.forgotPasswordLink}
                >
                  {intl.formatMessage({
                    id: 'signin.forgot.password.label',
                  })}
                </Link>
              </form>
              <Divider className={classes.divider} />
              <Box className={classes.linkWrappers}>
                <Typography
                  component="h1"
                  variant="h4"
                  className={classes.registerHint}
                >
                  {intl.formatMessage({ id: 'register.hint.label' })}
                </Typography>
                <Link
                  to={DEFAULT_REGISTER_ROUTE}
                  className={classes.registerLink}
                >
                  <ConfirmButton
                    className={classes.actionButton}
                    disabled={signInLoading}
                    color="secondary"
                    fullWidth
                  >
                    {intl.formatMessage({
                      id: 'register.link.label',
                    })}
                  </ConfirmButton>
                </Link>
                <Link to={DEFAULT_FAQ_ROUTE} className={classes.registerLink}>
                  <ConfirmButton
                    className={classes.infoButton}
                    disabled={signInLoading}
                    fullWidth
                  >
                    {intl.formatMessage({
                      id: 'signin.faq.label',
                    })}
                  </ConfirmButton>
                </Link>
              </Box>
              <Box className={classes.linkWrappers}></Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <TipsContainer
        primaryTitle={intl.formatMessage({
          id: 'signin.tips.primary.title',
        })}
        secondaryTitle={intl.formatMessage({
          id: 'signin.tips.secondary.title',
        })}
        hintMessage={intl.formatMessage({
          id: 'signin.subscribe.hint.message',
        })}
        hideOnLargeScreen={true}
        hideTips={true}
      >
        {PERSONAS_PRIORITY.map((personaItem, index) => (
          <TipsItem
            key={`${index}-${personaItem.caption}`}
            image={personaItem.image}
            caption={intl.formatMessage({ id: personaItem.caption })}
            className={classes.tipsItem}
          />
        ))}
      </TipsContainer>
      <Popup
        open={openTooltips}
        closeOnDocumentClick={false}
        overlayStyle={{
          position: 'unset',
        }}
        contentStyle={{
          position: 'absolute',
          bottom: '5%',
          left: '2%',
        }}
      >
        {(close) => <TooltipsCard close={close} />}
      </Popup>
    </PageContainer>
  );
};

export default SignIn;
